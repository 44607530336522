.text-cart {
  text-align: center;
  font-weight: 600;
  color: #555454;
  margin-bottom: 20px;
}

.card-order {
  width: 100%;
  min-width: 285px;
  border: none;
  background-color: #f8f7f7;
  border: 1px solid #c9c8c8;
}

.card-pai-order {
  display: flex;
  max-width: 100%;
  height: 95%;
  gap: 5px;
  margin-bottom: 20px;
}

@media (max-width:1336px) {
  .card-pai-order {
    overflow: auto;
  }
}

.card-list-order {
  height: 155px;
  min-height: 155px;
}

.print-order {
  color: #555454;
}

.print-order:hover {
  color: #000;
  transition: 0.5s;
  cursor: pointer;
}

.opstions-order{
  font-size: 1rem;
  color: rgb(69, 69, 214);
}

.opstions-order:hover{
  transition: 0.2s;
  text-decoration: underline;
}
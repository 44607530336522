.image-crop {
  opacity: 1;
  width: calc(1rem * 5) !important;
  height: calc(1rem * 5) !important;
  border-radius: 5px;
}

.icon-edit-crop {
  position: absolute;
  z-index: -1;
}

.icons-crop {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  flex-direction: column;
}

.icons-crop:hover .image-crop {
  z-index: -2;
  opacity: 0.2;
  transition: 0.5s;
}
@media print {
  .header-filter{
    display: none;
  }

  #title-fiscal {
    display: none;
  }
  
  .text-title-fiscal{
    display: none;
  }

  .sb-topnav{
    display: none;
  }

  .td-acoes{
    display: none;
  }

  .th-acoes{
    display: none;
  }

  .th-acoes{
    display: none;
  }

  .embeddables-iframe{
    display: none;
  }

  .td-status span{
    color: black;
  }
}
.map {
  height: 50vh;
}

.map-marker {
  margin-top: -30px;
}

.address {
  width: 30%;
  border: 1px solid #ccc;
  padding: 12px;
  border-radius: 4px;
  font-size: 14px;
  position: absolute;
  left: 40%;
  margin-left: -120px;
  margin-top: 12px;
}

.address::focus{
  border-color: #ccc!important;
}


.addressField {
  box-sizing: border-box;
  border: 1px solid transparent;
  width: 100%;
  height: 32px;
  margin-bottom: 8px;
  padding: 0 12px;
  border-radius: 4px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  font-size: 14px;
  outline: none;
  text-overflow: ellipses;
}

.cart-container {
  display: flex;
  gap: 20px;
  overflow: auto;
}

@media (max-width: 1560px) {
  .cart-container {
    justify-content: start;
  }
}

.report-list-type-orders {
  margin-top: 20px;
}

p {
  margin: 0px;
  padding: 0px;
}


.dflex {
  display: flex;
  align-items: center;
}

.modal-order-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

@media (max-width:1100px) {
  .modal-order-content {
    grid-template-columns: 1fr;
  }
}

.react-datalist-input__listbox {
  max-height: 200px !important;
  overflow: auto !important;
}


.element-scrol-produtcts-add {
  scrollbar-width: thin;
  scrollbar-color: #dcdcdc transparent;
}

.element-scrol-produtcts-add::-webkit-scrollbar {
  width: 3px;
  height: 3px;
  /* A altura só é vista quando a rolagem é horizontal */
}

.element-scrol-produtcts-add::-webkit-scrollbar-track {
  background: transparent;
  padding: 2px;
}

.element-scrol-produtcts-add::-webkit-scrollbar-thumb {
  background-color: #000;
}

@media (max-width:800px) {
  .card-pai-product {
    grid-template-columns: 1fr 1fr!important;
    flex: none!important;
  }
}
.tables-columns {
  display: flex;
  flex-wrap: wrap;
  max-height: 600px;
  overflow: auto;
  gap: 1rem;
}


.container-orders-mesa {
  display: flex;
}

@media screen and (max-width:400px) {
  #container-table{
    max-height: 100%!important;
    overflow: hidden!important;
  }
  .container-orders-mesa {
    flex-direction: column;
    height: 100%!important;
  }

  .list-orders-mesa {
    max-height: 225px!important;
  }

  .tables-columns{
    max-height: 200px!important;
    justify-content: center;
    align-items: center;
  }
}
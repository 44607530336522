.btn-plan{
  -webkit-text-size-adjust: 100%;
  --white: white;
  --gray-600: #485460;
  --primary-700: #0b0040;
  --primary-500: #2b00ff;
  --primary-600: #2000bd;
  --highlight-500: #f74926;
  --highlight-600: #a72106;
  --tertiary-300: #f4f6fe;
  --secondary-500: #1d84ff;
  --quaternary-500: #ff9700;
  --tertiary-400: #eaeffe;
  --grey-300: #dde2eb;
  --gray-500: #7f8f9f;
  --secondary-300-301: #f0f7ff;
  --secondary-400: #83bcff;
  --tertiary-500: #5c50ff;
  --gray-400: #c6cdd4;
  --tertiary-100: #f3f0ff;
  --gray-200: #f0f2f5;
  --gray-100: #f7f9fa;
  --quaternary-300: #fff9f0;
  --systemflow-lite-neutral--10-101\<deleted\|variable-326b6100\>: white;
  --highlight-300: #fef1ef;
  --secondary-600: #004eac;
  --secondary-300: #f0f7ff;
  --black: #30333c;
  --pure-black: #000;
  font-family: Manrope,sans-serif;
  -webkit-tap-highlight-color: transparent;
  white-space: normal;
  box-sizing: border-box;
  position: relative;
  line-height: inherit;
  border: 0;
  display: inline-block;
  background-color: var(--primary-500);
  color: var(--white);
  cursor: pointer;
  border-radius: .25rem;
  justify-content: center;
  align-items: center;
  padding: .75rem 2rem;
  font-size: 1rem;
  font-weight: 600;
  text-decoration: none;
  transition: background-color .3s ease-in-out;
  width: 100%;
  text-align: center;
}